import { Card, Tabs, Row } from "antd"
import QRcode from "~/public/static/qrcode.png"

const TabApp = () => (
  <>
    <Row justify="center">
      <img
        style={{ width: "128px", height: "128px", marginBottom: "1em" }}
        src="https://duckfiles.oss-cn-qingdao.aliyuncs.com/eleduck/assets/app.png"
        alt="扫码下载应用"
      />
    </Row>
    <Row justify="center">
      <p>下载APP，能及时收到回复或进展</p>
    </Row>
  </>
)

const TabWechat = () => (
  <>
    <Row justify="center">
      <img
        style={{ width: "128px", height: "128px", marginBottom: "1em" }}
        src={QRcode.src}
        alt="扫码关注公众号"
      />
    </Row>
    <Row justify="center">
      <p>关注微信公众号，订阅每周远程职位</p>
    </Row>
  </>
)

const items = [
  { label: "下载App", key: "1", children: <TabApp /> },
  { label: "公众号", key: "2", children: <TabWechat /> },
]

const WechatGroupCard = () => {
  return (
    <Card>
      <Tabs items={items} />
    </Card>
  )
}

export default WechatGroupCard
