import { connect } from "react-redux";
import BaseModal from "./BaseModal";
import { Button, Typography } from "antd";

const { Text } = Typography;

const CollaborationQrcodeModal = ({
  collaboration,
  isOpen,
  hideModal,
}: any) => {
  return (
    <BaseModal isOpen={isOpen} hideModal={hideModal}>
      <div className="text-center">
        <div className="group-desc">
          <pre>{collaboration?.qrcode_desc}</pre>
        </div>
        <img width={150} height={150} src={collaboration?.qrcode_url} />
        <div>
          <Text type="warning">扫码申请加入共学群请</Text>
        </div>
        <div>
          <Text type="warning">请务必备注“共学”二字</Text>
        </div>
        <Button
          className="mt-20"
          type="primary"
          onClick={() => {
            hideModal();
          }}
        >
          知道了
        </Button>
      </div>
    </BaseModal>
  );
};
const mapStateToProps = (state: any) => ({
  collaboration: state.app.collaboration,
});
// const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps
  // mapDispatchToProps
)(CollaborationQrcodeModal);
