import React from 'react';
import classNames from 'classnames';
import styles from './CategoryMark.module.css';

interface CategoryMarkProps {
  mark: string;
}

const CategoryMark: React.FC<CategoryMarkProps> = ({ mark }) => {
  const className = classNames(styles.categoryMark, styles[mark]);
  return <span className={className}>{mark}</span>;
};

export default CategoryMark;
