import settings from "~/settings"
import { useEffect, useState } from "react"
import { fetchRandomTalentPosts } from "~/redux/actions/talent"

import Link from "next/link"
import { Card, Skeleton, Space } from "antd"
import SuggestItem from "~/components/app/SuggestItem"
import PostLink from "~/components/post/PostLink"

const TalentPostList = () => {
  const [posts, setPosts] = useState(null)

  const loadTalentPosts = () => {
    fetchRandomTalentPosts().then(({ posts }) => setPosts(posts))
  }

  useEffect(() => {
    loadTalentPosts()
  }, [])

  return (
    <Card
      title="远程全职推荐"
      extra={
        <Space>
          <a className="text-primary" onClick={loadTalentPosts}>刷新</a>
          <Link href={`/categories/${settings.talentCategoryId}`}>
            <a className="text-primary">查看全部</a>
          </Link>
        </Space>
      }
    >
      <Skeleton loading={!posts}>
        {posts && posts.map((post) => (
          <SuggestItem key={post.id}>
            <PostLink post={post} key={post.id} isText />
          </SuggestItem>
        ))}
      </Skeleton>
    </Card>
  )
}

export default TalentPostList
