import { useRouter } from "next/router"
import { Dropdown, Button } from "antd"
import { CaretDownOutlined, SortAscendingOutlined } from "@ant-design/icons"
import { updateLinkQuery } from "~/utils/route"
import { setCookie, removeCookie } from "~/utils/cookie"

const items = [
  { label: "默认", key: "default" },
  { label: "最新", key: "new"     },
]

const PostSortDropdown = ({ sort, localization }) => {
  const router = useRouter()
  const onClick = ({ key }) => {
    if (localization) {
      if (key === "default") {
        removeCookie("sort")
      } else {
        setCookie("sort", key)
      }
    }

    const link = updateLinkQuery(router, (query) => {
      // 切换分页方式的时候，重置分页
      delete query["page"]

      if (key === "default") {
        delete query["sort"]
      } else {
        query.sort = key
      }
    });

    if (key) router.push(link.as)
  }

  const currentSort = items.find(x => x.key === sort) || items[0]

  return (
    <span>
      <SortAscendingOutlined className="mr-5" />
      <Dropdown menu={{ items, onClick }} trigger={["click"]}>
        <Button size="small">
          {currentSort.label} <CaretDownOutlined />
        </Button>
      </Dropdown>
    </span>
  );
};

export default PostSortDropdown;