import { Empty } from "antd";
import { ReactNode } from "react";
import blankIcon from "~/public/static/blank.png";

const EmptyList = ({
  children,
  description,
  hasData = false,
}: {
  children?: ReactNode;
  description?: string;
  hasData?: boolean;
}) => {
  if (hasData) return <>{children}</>;

  return (
    <div className="my-50">
      <Empty description={description || "暂无数据"} image={blankIcon.src} />
    </div>
  );
};

export default EmptyList;
