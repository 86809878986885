import qs from "querystringify";
import _ from "lodash";

export const updateLinkQuery = (router, queryUpdater) => {
  const [pathname, queryString = ""] = router.asPath.split("?");
  const query = qs.parse(queryString);
  queryUpdater(query);
  const newQueryString = qs.stringify(query);

  return {
    href: [router.pathname, newQueryString].filter((x) => x).join("?"),
    as: [pathname, newQueryString].filter((x) => x).join("?"),
  };
};

export const userUrl = (user) => {
  return `/users/${user.id}`;
};

export const postUrl = (post) => {
  return post.category_code === "talent" ||
    (post.category && post.category.code === "talent")
    ? `/tposts/${post.id}`
    : `/posts/${post.id}`;
};

export const getHref = (post) => {
  return post.category_code === "talent" ||
    (post.category && post.category.code === "talent")
    ? "/tposts/[id]"
    : "/posts/[id]";
};

export const userPostsUrl = (user, params = {}) => {
  return `/users/${user.id}${qs.stringify(params, true)}`;
};

export const userProfileUrl = (user) => {
  return `/users/${user.id}/profile`;
};

export const buildCategoryUrl = (categoryId, path = "/categories") => {
  if (categoryId === "follows") {
    return {
      href: "/follows",
      as: "/follows",
    };
  } else if (categoryId === "all") {
    return {
      href: "/",
      as: "/",
    };
  } else if (categoryId) {
    return {
      href: `${path}/[category]`,
      as: `${path}/${categoryId}`,
    };
  } else {
    return {
      href: "/",
      as: "/",
    };
  }
};
