import SiderNavigationCard from "~/components/sider/SiderNavigationCard"
import UserSuggestCard from "~/components/sider/UserSuggestCard"
import SiderPromoList from "~/components/sider/SiderPromoList"
import TalentPostList from "~/components/sider/TalentPostList"
import WechatGroupCard from "~/components/site/WechatGroupCard"
import SiteRulesCard from "~/components/site/SiteRulesCard"

const SiderPrimary = () => {
  return (
    <>
      <SiderNavigationCard />
      <TalentPostList />
      <WechatGroupCard />
      <UserSuggestCard />
      <SiteRulesCard />
      <SiderPromoList />
    </>
  )
}

export default SiderPrimary