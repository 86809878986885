import _ from "lodash";
import { hasAnyRole } from "./user";
import contributorIcon from "~/public/static/icons/contributor.png";
import enCluberIcon from "~/public/static/icons/en_cluber.png";
import paidCategoryMemberIcon from "~/public/static/icons/collaboration.png";

// Scroll position
const SCROLL_POSITION_KEY = "SCROLL_POSITION";

export const setScrollPosition = (scrollPosition) => {
  if (typeof localStorage === "undefined") return;
  localStorage.setItem(SCROLL_POSITION_KEY, scrollPosition.toString());
};

export const loadScrollPosition = () => {
  if (typeof localStorage === "undefined") return 0;
  const scrollPosition = localStorage.getItem(SCROLL_POSITION_KEY);
  if (!scrollPosition) return 0;
  return Number.parseInt(scrollPosition);
};

export const setNavigationStorage = (postId) => {
  if (typeof localStorage === "undefined") return;
  localStorage.setItem(postId, true);
};

export const getNavigationStorage = (postId) => {
  if (typeof localStorage === "undefined") return false;
  const localFlag = localStorage.getItem(postId);
  if (localFlag === null) return true;
  return false;
};

export const getUserBadge = ({ user, avatarSize = 64 }) => {
  const isContributor = hasAnyRole(user, ["contributor"]);
  const isEnClubMember = hasAnyRole(user, ["en_club_member"]);
  const isPaidCategoryFollower = hasAnyRole(user, ["paid_category_follower"]);
  const iconSize = avatarSize * 0.4;
  const commonStyle = {
    position: "absolute",
  };

  let avatarBorderSize = 0;

  if (isContributor || isEnClubMember || isPaidCategoryFollower)
    avatarBorderSize = avatarSize * 0.04;

  const map = {
    en_club_member: {
      avatarBorderStyle: {
        border: `${avatarBorderSize}px solid #FFCB6C`,
      },
      bordered: true,
      src: enCluberIcon.src,
      title: "英语俱乐部成员",
      style: {
        ["UserAvatar"]: {
          ...commonStyle,
          top: -avatarSize * 0.09 + "px",
          right: -avatarSize * 0.1 + "px",
          width: "75%",
        },
        ["AppMenuRight"]: {
          ...commonStyle,
          top: -avatarSize * 0.2 + "px",
          left: avatarSize * 0.5 + "px",
          width: avatarSize * 1.5 * 0.4,
          height: avatarSize * 0.4,
        },
      },
    },
    contributor: {
      avatarBorderStyle: {
        border: `${avatarBorderSize}px solid #FFCB6C`,
      },
      bordered: true,
      src: contributorIcon.src,
      title: "共建者",
      style: {
        ["UserAvatar"]: {
          ...commonStyle,
          top: 0,
          right: 0,
          width: "40%",
        },
        ["AppMenuRight"]: {
          ...commonStyle,
          top: -avatarSize * 0.13 + "px",
          left: avatarSize * 0.7 + "px",
          width: iconSize,
          height: iconSize,
        },
      },
    },
    paid_category_member: {
      avatarBorderStyle: {
        border: `${avatarBorderSize}px solid #FFCB6C`,
      },
      bordered: true,
      src: paidCategoryMemberIcon.src,
      title: "共学共创成员",
      style: {
        ["UserAvatar"]: {
          ...commonStyle,
          top: 0,
          right: 0,
          width: "40%",
        },
        ["AppMenuRight"]: {
          ...commonStyle,
          top: -avatarSize * 0.07 + "px",
          left: avatarSize * 0.7 + "px",
          width: iconSize,
          height: iconSize,
        },
      },
    },
  };
  if (isContributor) return map.contributor;
  if (isEnClubMember) return map.en_club_member;
  if (isPaidCategoryFollower) return map.paid_category_member;

  return {};
};
