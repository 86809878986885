import { useRouter } from "next/router";

import { Avatar } from "antd";
import UserBadge from "./UserBadge";

import { avatarUrl } from "~/utils/app";
import { hasAnyRole } from "~/utils/user";

import levelIcon from "~/public/static/icons/level.png";

const UserAvatar = ({ user, size, showLevel = false, ...props }) => {
  const router = useRouter();

  const handleLinkToUser = (id) => {
    window.open(`/users/${id}`, "_blank");
  };

  const isContributor = hasAnyRole(user, ["contributor"]);
  const isEnClubMember = hasAnyRole(user, ["en_club_member"]);
  const isPaidCategoryFollower = hasAnyRole(user, ["paid_category_follower"]);
  let avatarBorderSize = 0;
  if (isContributor || isEnClubMember || isPaidCategoryFollower || showLevel)
    avatarBorderSize = size * 0.04 || 2;

  return (
    <div
      onClick={handleLinkToUser.bind(null, user.id)}
      style={{ display: "inline-block" }}
    >
      <div
        style={{ display: "inline-block", cursor: "pointer", ...props.style }}
        {...props}
      >
        <div style={{ position: "relative" }}>
          <Avatar
            alt="头像"
            style={{ border: `${avatarBorderSize}px solid #FFCB6C` }}
            src={avatarUrl(user, 64)}
            size={size}
          />
          <UserBadge user={user} avatarSize={size} />
          {showLevel && (
            <div className={`avatar-level ${size < 30 ? "small" : ""}`}>
              <img src={levelIcon.src} alt="等级" />
              {user.level}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAvatar;
