import { useRouter } from "next/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseModal from "./BaseModal";
import { showAppModal } from "../../redux/actions/app";

import { InfoCircleOutlined } from "@ant-design/icons";
import { isWechatBrowser, authUrlSilently } from "../../utils/wechat";
import { Button, Menu } from "antd";
import { isMobile } from "react-device-detect";

const RestrictedOperationModal = ({ isOpen, hideModal, showAppModal }: any) => {
  const router = useRouter();
  return (
    <BaseModal isOpen={isOpen} hideModal={() => hideModal()}>
      <Menu
        mode="horizontal"
        selectedKeys={["tips"]}
        items={[{ label: "提示", key: "tips" }]}
      />
      <div className={`${isMobile ? "font-13" : "font-16"} text-center my-30`}>
        <div>抱歉哈，由于您数次违反社区规则，导致电量为负，</div>
        <div>被限制评论。请充电，使电量为正后再评论。</div>
        <Button
          className="mt-20"
          type="primary"
          onClick={() => {
            if (isWechatBrowser()) {
              router.push({
                pathname: "/profile/info",
                query: { redirectUrl: router.asPath },
              });
            } else {
              showAppModal("TopUpModal");
            }
          }}
        >
          去充电
        </Button>
        <div
          style={{ fontSize: "0.8rem", color: "#cccccc", marginTop: "1rem" }}
        >
          <a href="https://eleduck.com/posts/6GzfGe" target="_blank">
            <InfoCircleOutlined />
            &nbsp;电量的作用及如何获取电量
          </a>
        </div>
        <div style={{ fontSize: "0.8rem", color: "#cccccc" }}>
          <a href="https://eleduck.com/posts/qzfyov" target="_blank">
            <InfoCircleOutlined />
            &nbsp;充值相关及发票说明
          </a>
        </div>
      </div>
    </BaseModal>
  );
};
const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showAppModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedOperationModal);
