// react
import { useRouter } from "next/router";

// comps
import { Card, Row, Col, Button } from "antd";
import { FormOutlined } from "@ant-design/icons";

// redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAppModal } from "~/redux/actions/app";

const NewPostCard = (props) => {
  const { category, hideNewPost = false, hideNewJob = false } = props;
  const router = useRouter();

  const handleLinkToNewPost = () => {
    if (props.token) {
      let path = "/posts/new";
      if (category) {
        path += `?category=${category.id}`;
      }
      router.push(path);
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };

  const handleLinkToNewJob = () => {
    if (props.token) {
      props.showAppModal("NewJobModal");
    } else {
      props.showAppModal("PhoneSigninModal");
    }
  };

  // 控制移动端按钮的宽度，如果有两个按钮，则各占一半，否则全宽
  const mobileSpan = (!hideNewJob && !hideNewPost) ? 12 : 24;

  return (
    <Card className={props.className}>
      <Row gutter={[8, 8]}>
        {!hideNewJob && (
          <Col xs={mobileSpan} sm={mobileSpan} md={24}>
            <Button
              type="primary"
              icon={<FormOutlined />}
              onClick={handleLinkToNewJob}
              block
            >
              发布招聘
            </Button>
          </Col>
        )}
        {!hideNewPost && (
          <Col xs={mobileSpan} sm={mobileSpan} md={24}>
            <Button
              type="primary"
              icon={<FormOutlined />}
              onClick={handleLinkToNewPost}
              block
            >
              发布话题
            </Button>
          </Col>
        )}
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewPostCard);
