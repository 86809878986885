// react
import { useRouter } from "next/router";
// views
import { Dropdown, Menu } from "antd";

import _ from "lodash";
import { isMobile } from "react-device-detect";
import path from "path";

export default (props) => {
  const router = useRouter();
  const pathname = router.asPath;

  let selected = [];
  if (pathname === "/") {
    selected = ["home"];
  } else if (pathname === "/cities" || router.pathname === "/cities/[id]") {
    selected = ["cities"];
  } else if (
    pathname === "/english" ||
    pathname === "/fullstack" ||
    pathname === "/ai" ||
    pathname === "/business"
  ) {
    selected = ["camp"];
  } else if (pathname === "/groups") {
    selected = ["groups"];
  } else if (pathname.includes("/collaborations")) {
    selected = ["collaborations"];
  } else if (pathname === "/posts/oQfjxr") {
    selected = ["resource"];
  } else {
    selected = ["home"];
  }

  const items = [
    {
      label: <a href="/">社区</a>,
      key: "home",
    },
    {
      label: <a href="/groups">群组</a>,
      key: "groups",
    },
    {
      label: <a href="/cities">城市</a>,
      key: "cities",
    },
    {
      label: <a href="/collaborations">共学共创</a>,
      key: "collaborations",
    },
    // {
    //   label: <a href="/links">发现</a>,
    //   key: "links",
    // },
    {
      label: <a href="https://talent.eleduck.com">人才库</a>,
      key: "resource",
    },
    {
      label: (
        <a
          href={isMobile ? "" : "/english"}
          onClick={(e) => {
            if (isMobile) {
              e.preventDefault();
            }
          }}
        >
          训练营
        </a>
      ),
      key: "camp",
      popupClassName: "camp-menu",
      children: [
        {
          label: (
            <a href="/english" className="app-menu-left-submenu">
              <div>
                <div>远程工作</div>
                <div>求职训练营</div>
              </div>
            </a>
          ),
          key: "english",
          className: "camp-menu-item",
        },
        {
          label: (
            <a
              href="https://eleduck.com/posts/pqfa48"
              className="app-menu-left-submenu"
            >
              {/* <div>远程Web3</div>
              <div>全栈训练营</div> */}
              <div>web2-web3</div>
              <div>前端提升营</div>
            </a>
          ),
          key: "fullstack",
          className: "camp-menu-item",
        },
        {
          label: (
            <a href="/business" className="app-menu-left-submenu">
              <div>职场英语</div>
              <div>1对1陪学训练营</div>
            </a>
          ),
          key: "business",
          className: "camp-menu-item",
        },
        // {
        //   label: (
        //     <a href="/ai" className="app-menu-left-submenu">
        //       <div className="single-line">AI 训练营</div>
        //     </a>
        //   ),
        //   key: "ai",
        //   className: "camp-menu-item",
        // },
      ],
    },

    // {
    //   label: <a href="/web3">Web3</a>,
    //   key: "web3",
    // },
  ];

  return (
    <Menu
      mode={props.mode}
      className="app-menu-left"
      selectedKeys={selected}
      items={items}
    />
  );
};
