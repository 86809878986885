import moment from "moment";
import qs from "querystringify";
import cutString from "cut-string";
import "moment/locale/zh-cn";

const DEFAULT_AVATAR =
  "https://duckfiles-dev.oss-cn-qingdao.aliyuncs.com/eleduck/avatar/default.jpg";

export const imageUrl = (url, version) => {
  return `${url}!${version}`;
};

export const avatarUrl = (user, version) => {
  const url = (user && user.avatar_url) || DEFAULT_AVATAR;
  if (/aliyuncs\.com/.test(url)) {
    return imageUrl(url, version);
  } else {
    return url;
  }
};

export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm");
};

export const timeAgo = (date) => {
  moment.locale("zh-CN");
  return moment(date).fromNow();
};

export const checkOwner = (item, user) => {
  const itemUserId = item.user.id;
  const userId = user && user.id;
  return itemUserId === userId;
};

export const truncate = (text, length = 20) => {
  return cutString(text || "", length, { ellipsis: true });
};

export const calcVaildPromoDateWithTimeZone = (p) => {
  let startDate = new Date("2000-01-01");
  let endDate = new Date("2050-01-01");
  if (p.start_date && p.end_date) {
    const utcStartDate = new Date(new Date(p.start_date).setUTCHours(-8));
    const utcEndDate = new Date(new Date(p.end_date).setUTCHours(-8));
    utcEndDate.setDate(utcEndDate.getDate() + 1);
    startDate = utcStartDate;
    endDate = utcEndDate;
  }
  return { startDate, endDate };
};

export const pushUnique = (arr, item) => {
  const index = arr.indexOf(item);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  arr.push(item);
};

export const highlightContent = (keyword, content) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  const escapedKeyword = escapeRegExp(keyword);
  const regex = new RegExp(`(${escapedKeyword})`, "gi");
  return content.replace(regex, '<span style="color:#f9ba48">$1</span>');
};

export const findMatchingEle = (category) => {
  if (!category.paid) return 0;
  const sortedData = category.paid_followers_count_rules.sort(
    (a, b) => a.count - b.count
  );
  for (let i = 0; i < sortedData.length; i++) {
    if (category.followed_count < sortedData[i].count) {
      return sortedData[i].ele;
    }
  }
  return sortedData[sortedData.length - 1].ele;
};
