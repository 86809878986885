const defaults = {
  recaptchaSiteKey: "6LeEBz8kAAAAAHayGceI8oKeuIkYzDko5Xmn9INx",
};
const settings = {
  development: {
    ...defaults,
    env: "development",
    // appUrl: "http://test.eleduck.com:8080",
    // apiUrl: "http://test.eleduck.com:3000",
    // wsUrl: "ws://test.eleduck.com:3000/cable",
    appUrl: "http://localhost:8080",
    apiUrl: "http://localhost:3000",
    // appUrl: "http://192.168.101.10:8080",
    // apiUrl: "http://192.168.101.10:3000",
    talentUrl: "http://localhost:5001",
    talentApiUrl: "http://localhost:3001",
    talentCategoryId: process.env.talentCategoryId || 1,
    cookieTokenName: "token_development",
    wsUrl: "ws://localhost:3000/cable",
    wxAppId: "wxd8e13aaa0a26a720",
    baiduAnalytic: null,
  },
  staging: {
    ...defaults,
    env: "staging",
    appUrl: "https://app.stage.eleduck.com",
    apiUrl: "https://svc.stage.eleduck.com",
    talentUrl: "https://talent.stage.eleduck.com",
    talentApiUrl: "https://talent-svc.stage.eleduck.com",
    talentCategoryId: 36,
    domain: "stage.eleduck.com",
    cookieTokenName: "token_staging",
    wsUrl: "wss://svc.stage.eleduck.com/cable",
    // wxAppId: 'wxd8e13aaa0a26a720'
    wxAppId: "wxc7761b6c8088927e",
    baiduAnalytic: null,
    ga: "G-9VSN7NGKR7",
  },
  production: {
    ...defaults,
    env: "production",
    appUrl: "https://eleduck.com",
    apiUrl: "https://svc.eleduck.com",
    talentUrl: "https://talent.eleduck.com",
    talentApiUrl: "https://talent-svc.eleduck.com",
    talentCategoryId: 22,
    cookieTokenName: "token",
    domain: "eleduck.com",
    wsUrl: "wss://svc.eleduck.com/cable",
    wxAppId: "wxc7761b6c8088927e",
    baiduAnalytic: "3a5a64b389adc6ab16d0fae5250b560c",
    ga: "G-9HDHLTYPP2",
  },
};

module.exports = settings[process.env.nextEnv || "development"];
