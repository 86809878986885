import { Card, Row, Col, Divider, Skeleton } from "antd"
import EmptyList from "~/components/app/EmptyList"
import PostListItem from "~/components/post/PostListItem"
import PostSortDropdown from "~/components/post/PostSortDropdown"
import Pager from "~/components/site/Pager"

const PostList = ({ postList, sort }) => {
  return (
    <Card>
      <Skeleton loading={!postList}>
        {postList && (
          <>
            <Row justify="space-between">
              <Col>
                <span>共 {postList.pager ? postList.pager.total_count : 0} 个帖子</span>
              </Col>
              <Col>
                <PostSortDropdown localization sort={sort} />
              </Col>
            </Row>

            <Divider style={{ marginTop: "20px", marginBottom: 0 }} />

            <EmptyList hasData={postList.posts.length > 0}>
              {postList.posts.map((post) => <PostListItem post={post} key={post.id} />)}
            </EmptyList>

            <Pager pager={postList && postList.pager} onPageChange={() => {}} />
          </>
        )}
      </Skeleton>
    </Card>
  )
}

export default PostList
