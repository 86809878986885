import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fireEvent } from "~/redux/actions/app";
import { getEleProducts } from "~/redux/actions/app";
import { Menu } from "antd";
import BaseModal from "./BaseModal";
import { isMobile } from "react-device-detect";
import { authRequest } from "~/utils/api";
import QRCode from "qrcode.react";
import { getUserInfo } from "~/redux/actions/user";
import { message } from "antd";
import { InfoCircleOutlined, WechatOutlined } from "@ant-design/icons";
import { isWechatBrowser } from "~/utils/wechat";
import { wsUrl } from "~/settings";
import React from "react";
import eleIcon from "~/public/static/icons/ele@3x.png";

/**
 * 充值购买电量的弹窗。
 */
class TopUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedID: -1,
      payResult: 0, // 0等待或无状态；1成功；2失败。
      payMessage: "",
      qr: "",
      redirectUrl: "",
      tempCode: props.user.temp_code,
      productId: "",
      isButtonDisabled: false,
    };

    // 是否是在微信浏览器内。
    this.isWechat = isWechatBrowser();

    // JSAPI支付参数。
    this.jsAPIPayParams = null;

    // WebSocket对象。
    this.theWS = null;

    // 等待扫码的毫秒数。
    this.waiting = 5000;

    // 一个计时器。
    this.theTimeout = null;

    // 支付类型。
    // wechat_jsapi, wechat_mweb, wechat_native 微信公众号，微信网页，微信扫码
    // this.payKind = 'wechat_jsapi'
    this.payKind = isMobile
      ? this.isWechat
        ? "wechat_jsapi"
        : "wechat_mweb"
      : "wechat_native";

    this.handleGetMessage = this.handleGetMessage.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        tempCode: this.props.user.temp_code,
      });
    }, 1000);

    this.getProducts();
  }

  /**
   * 取得产品数据。
   */
  async getProducts() {
    const path = window.location.pathname;
    const redirectUrl = new URL(window.location.href).searchParams.get(
      "redirectUrl"
    );
    const isCollaborationPage =
      path.includes("/collaboration") ||
      path.includes("/collaborations") ||
      (redirectUrl &&
        (redirectUrl.includes("/collaboration") ||
          redirectUrl.includes("/collaborations")));

    let products = await getEleProducts();
    this.setState({
      products:
        isCollaborationPage ||
        (redirectUrl && redirectUrl.includes("collaboration"))
          ? products
          : products.filter((item) => item.strategy !== "limited"),
    });
  }

  /**
   * 创建WebSocket连接。
   */
  createWebSocket() {
    this.theWS = new WebSocket(`${wsUrl}?token=Bearer${this.props.token}`);
    this.theWS.onopen = (event) => {
      // 订阅。
      this.theWS.send(
        JSON.stringify({
          command: "subscribe",
          identifier: '{"channel":"OrderChannel"}',
        })
      );
    };
    this.theWS.onmessage = this.handleGetMessage;
  }

  /**
   * 点选商品。
   * @param {Number} selected 已选的商品id
   * @param {Number} index 已选的商品索引
   */
  handleSelect(selected, index) {
    if (this.theTimeout) {
      clearTimeout(this.theTimeout);
    }

    this.setState({
      selectedID: index,
      payResult: 0,
    });

    if (isMobile && this.isWechat) {
      this.setState({ productId: selected });
    } else {
      this.order(selected);
    }

    // 延迟后连接websocket。
    this.theTimeout = setTimeout(() => {
      if (
        !this.theWS ||
        this.theWS === undefined ||
        (this.theWS.readyState != 0 && this.theWS.readyState != 1)
      ) {
        this.createWebSocket();
      }
    }, this.waiting);
  }

  /**
   * 下单。
   * @param {Number} productId 商品id
   */
  async order(productId) {
    let payParams = await this.getPayingParams(
      this.props.token,
      productId,
      this.state.tempCode
    );

    if (isMobile) {
      if (this.isWechat) {
        this.jsAPIPayParams = payParams.wechat_jsapi.pay_params;
      } else {
        this.setState({
          redirectUrl: payParams.wechat_mweb.mweb_url,
        });
      }
    } else {
      this.setState({
        qr: payParams.wechat_native.code_url,
      });
    }
  }

  async onClickJSAPIPay() {
    if (this.state.isButtonDisabled) return;
    this.setState({ isButtonDisabled: true });
    let payParams = await this.getPayingParams(
      this.props.token,
      this.state.productId,
      this.state.tempCode
    );
    if (isMobile && this.isWechat) {
      this.jsAPIPayParams = payParams.wechat_jsapi.pay_params;
    }
    this.wechatJSAPIPay();
  }

  /**
   * 请求指定商品的支付参数。
   * @param {String} token 当前用户token
   * @param {Number} product_id 商品id
   * @param {String} code 用于获取微信openid的code
   */
  async getPayingParams(token, product_id, code) {
    return authRequest(token, {
      url: `/orders`,
      method: "POST",
      data: {
        kind: this.payKind,
        product_id: product_id,
        code: code,
      },
    })
      .then((res) => (res.status === 200 ? res.data : null))
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * WebSocket接收到消息的回调。
   * @param {Object} resMessage 消息体
   */
  handleGetMessage(resMessage) {
    let whatsUp = null;
    try {
      whatsUp = JSON.parse(resMessage.data);
    } catch (error) {
      return console.error(resMessage.data);
    }

    if (
      whatsUp.identifier &&
      whatsUp.identifier.indexOf("OrderChannel") > 0 &&
      whatsUp.message
    ) {
      // 收到来自已订阅的频道的推送。

      // 断开ws连接。
      this.destroyWebSocket();

      if (
        whatsUp.message.order_status &&
        whatsUp.message.order_status === "pay_success"
      ) {
        // 更新用户信息。
        this.props.getUserInfo(this.props.token);

        // 弹提示。
        message.success("购买电量成功！");

        this.props.hideModal();

        // this.setState({
        //   payResult: 1,
        //   payMessage: '购买成功'
        // });
      } else {
        // 弹提示。
        message.success("购买电量失败！");

        this.setState({
          payResult: 2,
          payMessage:
            "购买失败。请与管理员联系。订单号：" + whatsUp.message.order_oid,
        });
      }
    }
  }

  /**
   * 关闭websocket连接。
   */
  destroyWebSocket() {
    if (this.theWS) {
      this.theWS.send(
        JSON.stringify({
          command: "unsubscribe",
          identifier: '{"channel":"OrderChannel"}',
        })
      );
      this.theWS.close();
      this.theWS = null;
    }

    if (this.theTimeout) {
      clearTimeout(this.theTimeout);
      this.theTimeout = null;
    }
  }

  /**
   * 点击JSAPI支付方式的按钮。
   */
  wechatJSAPIPay() {
    if (WeixinJSBridge) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: this.jsAPIPayParams.appId,
          timeStamp: this.jsAPIPayParams.timeStamp,
          nonceStr: this.jsAPIPayParams.nonceStr,
          package: this.jsAPIPayParams.package,
          signType: this.jsAPIPayParams.signType,
          paySign: this.jsAPIPayParams.paySign,
        },
        function (res) {
          const redirectUrl = new URL(window.location.href).searchParams.get(
            "redirectUrl"
          );
          const successRedirectUrl = redirectUrl || window.location.pathname;
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            window.location.href = successRedirectUrl;
          } else {
            window.location.href = window.location.pathname;
          }
        }
      );
    } else {
      console.error("不是预期的微信内浏览器。");
      this.setState({ isButtonDisabled: false });
    }
  }

  componentWillUnmount() {
    this.destroyWebSocket();
  }

  render() {
    return (
      <BaseModal isOpen={this.props.isOpen} hideModal={this.props.hideModal}>
        <Menu
          mode="horizontal"
          selectedKeys={["charge"]}
          items={[{ label: "电量充值", key: "charge" }]}
        />

        <div className="mb-20">
          {this.state.products.map((choice, index) => (
            <div
              key={choice.id}
              className={`choice ${
                index === this.state.selectedID ? "selected" : ""
              }`}
              onClick={() => {
                this.handleSelect(choice.id, index);
              }}
              data-id={choice.id}
            >
              <div className="titleLabel">
                <img src={eleIcon.src} />
                &nbsp;{choice.title}
                {/* {choice.strategy === "limited" && (
                  <span style={{ color: "#f9ba48", fontSize: "0.8rem" }}>
                    （限时）
                  </span>
                )} */}
              </div>
              <div className="priceLabel">{choice.price}元</div>
            </div>
          ))}
        </div>

        {this.state.payResult === 0 && this.state.selectedID >= 0 ? (
          <div className="payBlock">
            {!isMobile ? (
              <div>
                <div style={{ padding: "0.5rem", fontSize: "0.8rem" }}>
                  <WechatOutlined />
                  &nbsp;微信扫码支付
                  {this.state.products[this.state.selectedID].price}元
                </div>
                <div>
                  <QRCode value={this.state.qr} size={162} />
                </div>
              </div>
            ) : (
              <div>
                {this.isWechat ? (
                  <a onClick={() => this.onClickJSAPIPay()}>
                    <span className="payButton">
                      <WechatOutlined />
                      &nbsp;微信支付
                      {this.state.products[this.state.selectedID].price}元
                    </span>
                  </a>
                ) : (
                  // this.state.redirectUrl != '' ? () : null
                  <a href={this.state.redirectUrl} target="_blank">
                    <span className="payButton">
                      <WechatOutlined />
                      &nbsp;微信支付
                      {this.state.products[this.state.selectedID].price}元
                    </span>
                  </a>
                )}
              </div>
            )}
          </div>
        ) : null}

        {
          // 显示支付结果。
          this.state.payResult > 0 ? <div>{this.state.payMessage}</div> : null
        }

        <div
          className="payBlock"
          style={{ fontSize: "0.8rem", color: "#cccccc", marginTop: "2rem" }}
        >
          <a href="https://eleduck.com/posts/6GzfGe" target="_blank">
            <InfoCircleOutlined />
            &nbsp;电量的作用及如何获取电量
          </a>
        </div>
        <div
          className="payBlock"
          style={{ fontSize: "0.8rem", color: "#cccccc" }}
        >
          <a href="https://eleduck.com/posts/qzfyov" target="_blank">
            <InfoCircleOutlined />
            &nbsp;充值相关及发票说明
          </a>
        </div>

        <style jsx>
          {`
            .choice {
              cursor: pointer;
              background: #f7f7f7;
              border-radius: 3px;
              border: 2px solid #f7f7f7;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              padding-left: 1rem;
              padding-right: 1rem;
              margin: 0 5px 5px 5px;
              font-size: 14px;
              color: #4a4a4a;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            }

            .choice.selected {
              border-color: #f9ba48;
            }

            .choice img {
              height: 14px;
            }

            .titleLabel {
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            .priceLabel {
              padding: 0px;
              padding-top: 0.2rem;
              margin-left: 0.2rem;
              margin-right: 0.2rem;
              font-size: 0.6rem;
              color: #4a4a4a;
            }

            .payBlock {
              text-align: center;
              margin: 0.5rem 0;
            }

            .payButton {
              background-color: #f9ba48;
              border-radius: 5px;
              border: none;
              color: white;
              padding-top: 1rem;
              padding-bottom: 1rem;
              padding-left: 2rem;
              padding-right: 2rem;
            }
            // @media screen and (max-width: 600px) {}
          `}
        </style>
      </BaseModal>
    );
  }
}

const mapStateToProps = (state) => ({
  // event: state.app.event,
  user: state.user.user,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fireEvent,
      getUserInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopUpModal);
