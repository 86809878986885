import { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useRouter } from "next/router";

import BaseModal from "./BaseModal";
import UserSigninNavs from "~/components/user/UserSigninNavs";
import QRCode from "qrcode.react";
import settings from "~/settings";

import { showAppModal } from "~/redux/actions/app";
import { createWechatToken, checkWechatToken } from "~/redux/actions/user";

import { authUrl, isWechatBrowser } from "~/utils/wechat";
import WechatAuthButton from "../app/WechatAuthButton";
import qs from "querystringify";

const Modal = (props) => {
  let timer = null;
  const router = useRouter();

  const createToken = () => {
    props.createWechatToken();
  };

  const checkToken = () => {
    if (!props.wechatToken || props.wechatTokenExpired) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }

    props.checkWechatToken(props.wechatToken, () => {
      timer = setTimeout(checkToken, 1000);
    });
  };

  const handleHideModal = () => {
    props.hideModal();
    clearTimeout(timer);
  };

  useEffect(() => {
    createToken();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (props.wechatToken) {
      checkToken();
    }
  }, [props.wechatToken]);

  useEffect(() => {
    if (props.token) {
      handleHideModal();
      window.location.reload(true);
    }
  }, [props.token]);

  return (
    <BaseModal isOpen={props.isOpen} hideModal={handleHideModal} showLogo>
      <UserSigninNavs selected="wechat" />

      <div className="text-center">
        {isWechatBrowser() ? (
          <div className="mb-15">
            <WechatAuthButton
              kind="signin"
              buttonText="使用微信登录"
              payload={{ redirect_url: router.asPath }}
            />
          </div>
        ) : (
          <div className="mb-15">
            <div className={props.wechatTokenExpired ? "expired" : ""}>
              <QRCode
                value={`${settings.appUrl}/auth/wechat/manual?${qs.stringify({
                  wechat_token: props.wechatToken,
                  kind: "signin",
                })}`}
                size={162}
              />
              <div className="refresh">
                已失效{" "}
                <a className="text-primary" onClick={createToken}>
                  刷新
                </a>
              </div>
            </div>

            <p className="mt-15 text-secondary">微信扫一扫，快速登录</p>
          </div>
        )}
      </div>

      <style jsx>
        {`
          .expired {
            width: 162px;
            height: 162px;
            margin: 0 auto;
            position: relative;
          }
          .refresh {
            position: absolute;
            display: none;
            text-align: center;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            line-height: 162px;
            background: rgba(153, 153, 153, 0.9);
          }
          .expired .refresh {
            display: block;
          }
        `}
      </style>
    </BaseModal>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
  wechatToken: state.user.wechatToken,
  wechatTokenExpired: state.user.wechatTokenExpired,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showAppModal,
      createWechatToken,
      checkWechatToken,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
