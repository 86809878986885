import { Tooltip } from "antd";

import { hasAnyRole } from "../../utils/user";
import { getUserBadge } from "../../utils/ui";

export default ({ user, position = "UserAvatar", avatarSize = 40 }) => {
  const isContributor = hasAnyRole(user, ["contributor"]);
  const isEnClubMember = hasAnyRole(user, ["en_club_member"]);
  const isPaidCategoryFollower = hasAnyRole(user, ["paid_category_follower"]);

  const { title, src, style } = getUserBadge({ user, position, avatarSize });
  return (
    <>
      {(isEnClubMember || isContributor || isPaidCategoryFollower) && (
        <Tooltip title={title}>
          <img src={src} style={{ ...style[position] }} alt={title} />
        </Tooltip>
      )}
    </>
  );
};
