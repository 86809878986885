import _ from "lodash";

import {
  SHOW_APP_MESSAGE,
  SHOW_APP_MODAL,
  OPEN_REQUEST,
  CLOSE_REQUEST,
  SET_REDIRECT,
  SET_TAG_GROUPS,
  SET_REPORTS,
  SET_CATEGORIES,
  SET_FOLLOWED_CATEGORIES,
  FIRE_EVENT,
  SET_PROMOS,
  SET_BLOGROLLS,
  SET_PREFS,
  SET_GROUPS,
  SET_GROUP_CATEGORIES,
  SET_LEVELS,
  SET_REPORT_PARAMS,
  SET_SLIDERS,
  SET_ELE_REWARDED_RECORDS,
  SET_USERS,
  SET_APPLICATION_PARAMS,
  SET_APPLICATIONS,
  SET_PENDING_COUNTS,
  CHECK_FOLLOWED_CITIES,
  SET_POST_CHECKED_TIME,
  SET_APK_VERSIONS,
  SET_COLLABORATION,
  CHECK_CATEGORY_FLAGS,
} from "../actions/app";

const initialState = {
  event: {},
  modal: null,
  message: null,
  request: {},
  tagGroups: null,
  categories: [],
  redirectUrl: null,
  promos: [],
  blogrolls: null,
  prefs: {},
  groups: [],
  groupCategories: [],
  report: {},
  pendingCountsResult: {
    report_count: 0,
    application_count: 0,
  },
  followedCities: {},
  postCheckedTime: 0,
  apkVersions: {},
  collaboration: null,
  flags: { followed: [], paid: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FIRE_EVENT:
      return {
        ...state,
        event: action.event,
      };
    case SET_PROMOS:
      return {
        ...state,
        promos: action.promos,
      };
    case SET_BLOGROLLS:
      return {
        ...state,
        blogrolls: action.blogrolls,
      };
    case SET_LEVELS:
      return {
        ...state,
        levels: action.levels,
      };
    case SHOW_APP_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case SHOW_APP_MODAL:
      return {
        ...state,
        modal: action.modal,
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirectUrl: action.url,
      };
    case OPEN_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          [action.key]: true,
        },
      };
    case CLOSE_REQUEST:
      return {
        ...state,
        request: _.omit(state.request, action.key),
      };
    case SET_TAG_GROUPS:
      return {
        ...state,
        tagGroups: action.tagGroups,
      };
    case SET_REPORTS:
      return {
        ...state,
        reports: action.reports,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case SET_FOLLOWED_CATEGORIES:
      return {
        ...state,
        followedCategories: action.followedCategories,
      };
    case SET_PREFS:
      return {
        ...state,
        prefs: action.prefs,
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };
    case SET_GROUP_CATEGORIES:
      return {
        ...state,
        groupCategories: action.groupCategories,
      };
    case SET_REPORT_PARAMS:
      return {
        ...state,
        report: action.report,
      };
    case SET_SLIDERS:
      return {
        ...state,
        sliders: action.sliders,
      };
    case SET_ELE_REWARDED_RECORDS:
      return {
        ...state,
        records: action.records,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case SET_APPLICATION_PARAMS:
      return {
        ...state,
        application: action.application,
      };
    case SET_APPLICATIONS:
      return {
        ...state,
        applications: action.applications,
      };
    case SET_PENDING_COUNTS:
      return {
        ...state,
        pendingCountsResult: action.pendingCountsResult,
      };
    case CHECK_FOLLOWED_CITIES:
      return {
        ...state,
        followedCities: action.followedCities,
      };
    case SET_POST_CHECKED_TIME:
      return {
        ...state,
        postCheckedTime: action.postCheckedTime,
      };
    case SET_APK_VERSIONS:
      return {
        ...state,
        apkVersions: action.apkVersions,
      };
    case SET_COLLABORATION:
      return {
        ...state,
        collaboration: action.collaboration,
      };
    case CHECK_CATEGORY_FLAGS:
      return {
        ...state,
        flags: {
          ...state.flags,
          ...action.flags,
        },
      };
    default:
      return state;
  }
};
