import { Row, Avatar, Skeleton, Col } from "antd"
import BorderedLink from "~/components/app/BorderedLink"
import CategoryMark from "~/components/categories/CategoryMark"

const CategoryNavs = ({ categories, selected }) => {
  return (
    <Skeleton paragraph={{ rows: 0 }} loading={!categories}>
      <Row justify="start" gutter={[6, 8]} style={{ fontSize: '1em' }}>
        <Col>
          <BorderedLink href="/" active={selected === "all"}>全部</BorderedLink>
        </Col>        
        <Col>
          <BorderedLink href="/follows" active={selected === "follows"}>我关注的</BorderedLink>
        </Col>
        {categories && categories.map(category => (
          <Col key={category.id}>
            <BorderedLink href={`/categories/${category.id}`} active={selected === category.id}>
              {category.logo_url && <Avatar src={category.logo_url} />}
              <span>{category.name}</span>
              {category.mark && <CategoryMark mark={category.mark} />}
            </BorderedLink>
          </Col>
        ))}
      </Row>
    </Skeleton>
  )
}

export default CategoryNavs
